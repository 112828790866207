import React, { useEffect } from 'react';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import Button from "~1-core/buttons/Button";
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';

const buttonEg = `import Button from "~1-core/buttons/Button";

<Button
    href='' //Required, obviously.
    skin='1 | 2 | 3 | 4' //Optional, default 1.
    target='_self' //Optional
    icon= '' //Optional

>
    //Button Text Here
</Button>
`;

const SgButtons = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    return (
        <SgLayout>
            <section className="sg-content">
                <div className="container">
                    <h1>Buttons</h1>
                </div>
            </section>
            <div className="project-example">
                <div className="container">
                    <div className="sg-eg-btns">
                        <Button href={`hello-world`}>Btn Skin 1</Button>
                        <Button skin={`2`} href={`/blah`}>Btn Skin 2</Button>
                        <div className="sg-btn-bg">
                            <Button skin={`3`} href={`/where-to`}>Btn Skin 3</Button>
                        </div>
                        <Button skin={`4`} href={`/blah`}>Btn Skin 4</Button>
                    </div>
                </div>
            </div>
            <section className="sg-content no-padding-bottom">
                <div className="container">
                    <h3>With icons</h3>
                </div>
            </section>
            <div className="project-example">
                <div className="container">
                    <div className="sg-eg-btns">
                        <Button href={`hello-world`} icon={`Envelope`}>Btn Skin 1</Button>
                        <Button skin={`2`} href={`/blah`} icon={`Envelope`}>Btn Skin 2</Button>
                        <div className="sg-btn-bg">
                            <Button skin={`3`} href={`/where-to`} icon={`Envelope`}>Btn Skin 3</Button>
                        </div>
                        <Button skin={`4`} href={`/blah`} icon={`Envelope`}>Btn Skin 4</Button>
                    </div>
                </div>
            </div>
            <section className="sg-content">
                <div className="container">
                    <h2>How to use</h2>
                    <CodeBlock
                        code={buttonEg}
                    />
                </div>
            </section>
        </SgLayout>
    );
};

export default SgButtons;
